<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Unternehmen bearbeiten</md-dialog-title>

    <md-dialog-content>
      <md-field>
        <label>Unternehmensname</label>
        <md-input v-model="name" />
        <span class="md-helper-text"
          >Bitte geben Sie hier einen neuen Unternehmensnamen ein.</span
        >
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="updateCompany"
        >Aktualisieren</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";

export default {
  name: "companyEditDialog",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: "",
    };
  },
  methods: {
    async closeDialog() {
      await this.$emit("close");
      this.reset();
    },
    async updateCompany() {
      await this.$emit("update-company", this.name);
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-content {
  width: 500px;
}
</style>
