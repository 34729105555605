import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import { getParams } from "@/services/api/utils";
import "./types";

const INVITE_URI = Settings.API_URI_INVITE;

/**
 * Get all invitations of a company
 * @return { Promise<InviteObject[]> }
 * @throws { APIError }
 **/
const getInvites = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${INVITE_URI}/`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Create a new invitation
 * @param {string} mail Mail to be invited
 * @return { Promise<InviteObject[]> }
 * @throws { APIError }
 **/
const createInvite = (mail, send_mail) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${INVITE_URI}/`,
      params: getParams({ send_mail }),
      data: {
        email: mail,
      },
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Resend an invitation
 * @param {string} id ID of the invite
 * @return { Promise<MessageObject> }
 * @throws { APIError }
 **/
const resendInvite = (id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${INVITE_URI}/`,
      params: getParams({ invite_id: id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Delete an invitation
 * @return { Promise<InviteObject[]> }
 * @throws { APIError }
 **/
const deleteInvite = (id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      url: `${INVITE_URI}/`,
      params: getParams({ invite_id: id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export { getInvites, createInvite, resendInvite, deleteInvite };
