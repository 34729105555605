<template>
  <iq-main>
    <!-- Loader -->
    <iq-loader :loading="loading" />

    <div class="container" v-if="!loading">
      <!-- Toolbar -->
      <iq-toolbar>
        <template v-slot:left>
          <!-- Avatar -->
          <div>
            <md-avatar class="md-avatar-icon">
              <md-icon class="md-primary">business</md-icon>
            </md-avatar>
          </div>

          <!-- Company name -->
          <div class="md-title">{{ company.name }}</div>

          <!-- Status -->
          <iq-status-pill :status="company.status" />
        </template>

        <template v-slot:right>
          <!-- Add button -->
          <md-button
            class="md-primary md-icon-button margin-right-1"
            @click="toggleDialog('invite')"
            v-if="isComponentActive(['admin'])"
          >
            <md-icon>person_add</md-icon>
            <md-tooltip md-direction="left">Benutzer hinzufügen</md-tooltip>
          </md-button>

          <!-- Edit button -->
          <md-button
            class="md-primary md-icon-button margin-0"
            @click="toggleDialog('update')"
            v-if="isComponentActive(['admin'])"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Unternehmen bearbeiten</md-tooltip>
          </md-button>
        </template>
      </iq-toolbar>

      <!-- Content -->
      <div class="wrapper">
        <div class="wrapper--left">
          <company-details :company="company" />
        </div>

        <div class="wrapper--right">
          <!-- Tabs -->
          <div class="iq-tabs">
            <div
              class="iq-tabs--item"
              :class="active === 'projects' ? 'iq-tabs--item--active' : ''"
              @click="setActive('projects')"
            >
              Projekte
            </div>

            <div
              class="iq-tabs--item"
              :class="active === 'users' ? 'iq-tabs--item--active' : ''"
              @click="setActive('users')"
            >
              Nutzer
            </div>

            <div
              class="iq-tabs--item"
              :class="active === 'invites' ? 'iq-tabs--item--active' : ''"
              @click="setActive('invites')"
            >
              Einladungen
            </div>

            <div
              class="iq-tabs--item"
              :class="active === 'apiToken' ? 'iq-tabs--item--active' : ''"
              @click="setActive('apiToken')"
              v-if="isComponentActive(['admin'])"
            >
              API Token
            </div>
          </div>

          <!-- Tabs content -->
          <div class="iq-tabs--content">
            <company-projects
              :projects="projects"
              v-if="active === 'projects'"
            />

            <company-users
              :users="users"
              v-if="active === 'users'"
              @updateSuccess="handleUserUpdate"
            />

            <company-invites
              :invites="invites"
              v-if="active === 'invites'"
              @resend="resendInvite"
              @delete="deleteInvite"
            />

            <div v-if="isComponentActive(['admin']) && active === 'apiToken'">

              <div class="item">
                <md-field>
                  <label>API Token</label>
                  <md-input type="password" v-model="apiToken" />
                </md-field>

                <div style="margin-right: auto">
                  <md-button class="md-accent" @click="createNewApiToken" style="margin: 0">Neu erstellen</md-button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialogs -->
    <company-invite-dialog
      :is-active="dialogs.invite"
      @invite-user="createInvite"
      @close="toggleDialog('invite')"
    />

    <company-edit-dialog
      :is-active="dialogs.update"
      @update-company="updateCompany"
      @close="toggleDialog('update')"
    />

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
    >
      <span>{{ errorMessage }}</span>
    </md-snackbar>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
    >
      <span>{{ successMessage }}</span>
    </md-snackbar>
  </iq-main>
</template>

<script>
import {
  getCompany,
  getCompanyInvites,
  getCompanyProjects,
  getCompanyUsers,
  updateCompany,
} from "@/services/api/company";
import { getApiToken, regenerateApiToken } from "@/services/api/api_token";
import successMixin from "@/mixins/successMixin";
import errorMixin from "@/mixins/errorMixin";
import loadingMixin from "@/mixins/loadingMixin";
import CompanyDetails from "@/components/company/companyDetails";
import CompanyProjects from "@/components/company/companyProjects";
import CompanyUsers from "@/components/company/companyUsers";
import CompanyInvites from "@/components/company/companyInvites";
import {
  createInvite,
  deleteInvite,
  resendInvite,
} from "@/services/api/invite";
import CompanyInviteDialog from "@/components/company/companyInviteDialog";
import CompanyEditDialog from "@/components/company/companyEditDialog";
import accessControlMixin from "@/mixins/accessControlMixin";

export default {
  name: "Company",
  components: {
    CompanyEditDialog,
    CompanyInviteDialog,
    CompanyInvites,
    CompanyUsers,
    CompanyProjects,
    CompanyDetails,
  },
  mixins: [loadingMixin, successMixin, errorMixin, accessControlMixin],
  data() {
    return {
      active: "projects",
      company: {},
      projects: [],
      users: [],
      invites: [],
      dialogs: {
        invite: false,
        update: false,
      },
      apiToken: "",
    };
  },
  created() {
    this.getCompany();
    this.getApiToken();
  },
  methods: {
    async getApiToken() {
      try {
        const res = await getApiToken();
        this.apiToken = res.data.api_token;
      } catch (e) {
        console.error(e);
      }
    },
    async createNewApiToken() {
      try {
        const res = await regenerateApiToken();
        this.apiToken = res.data.api_token;
      } catch (e) {
        console.error(e);
      }
    },
    async getCompany() {
      try {
        this.company = await getCompany();
        this.projects = await getCompanyProjects();
        this.users = await getCompanyUsers();
        this.invites = await getCompanyInvites();
        console.log(this.invites);
        this.toggleLoading();
      } catch (error) {
        console.error(error);
      }
    },
    async updateCompany(name) {
      try {
        this.company = await updateCompany(name);
        this.toggleDialog("update");
        this.toggleSuccess("Unternehmen wurde erfolgreich aktualisiert");
      } catch (error) {
        console.error(error);
        this.toggleDialog("update");
        this.toggleError(
          "Fehler beim aktualisieren. Bitte versuchen Sie es später noch einmal!"
        );
      }
    },
    async createInvite({mail, send_mail}) {
      try {
        this.invites = await createInvite(mail, send_mail);
        this.toggleDialog("invite");
        this.setActive("invites");
        this.toggleSuccess(`${mail} wurde erfolgreich eingeladen`);
      } catch (error) {
        console.error(error);
        this.toggleDialog("invite");
        this.toggleError(
          "Fehler beim versenden. Bitte versuchen Sie es später noch einmal!"
        );
      }
    },
    async resendInvite(id) {
      try {
        await resendInvite(id);
        this.toggleSuccess("Einladung wurde erfolgreich versendet!");
      } catch (error) {
        console.error(error);
        this.toggleError(
          "Fehler beim versenden. Bitte versuchen Sie es später noch einmal!"
        );
      }
    },
    async deleteInvite(id) {
      try {
        this.invites = await deleteInvite(id);
        this.toggleSuccess("Einladung wurde erfolgreich gelöscht!");
      } catch (error) {
        console.error(error);
        this.toggleError(
          "Fehler beim löschen. Bitte versuchen Sie es später noch einmal!"
        );
      }
    },
    setActive(state) {
      this.active = state;
    },
    toggleDialog(id) {
      this.dialogs[id] = !this.dialogs[id];
    },
    handleUserUpdate(updatedUser) {
      const userIndex = this.users.findIndex(
        (user) => user.id === updatedUser.id
      );
      this.users.splice(userIndex, 1);
      this.users.splice(userIndex, 0, updatedUser);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.profile-avatar-wrapper {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: $border-default;
  margin-right: 1rem;
  padding: 0.25rem;

  & > img {
    border-radius: 50%;
  }
}

.iq-pill {
  margin-left: 1rem;
}

.iq-tabs {
  display: flex;
  margin-bottom: 1rem;

  &--item {
    margin-right: 2rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 20px;
    font-weight: 500;
    color: var(--iq-gray-400);
    border-bottom: 3px solid transparent;
    cursor: pointer;
    transition: $transition;

    &:hover {
      border-bottom: 3px solid var(--iq-blue-100);
      color: var(--iq-blue-500);
    }

    &--active {
      border-bottom: 3px solid var(--iq-blue-500);
      color: var(--iq-blue-500);
    }
  }
}

.item {
  margin-top: 1rem;
  padding: $padding;
  border: 1px solid var(--iq-gray-300);
  border-radius: $border-radius;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include borderHover;

  & > span {
    margin-left: 1rem;
  }

  & > .md-menu {
    margin: 0 0 0 auto;
  }
}
</style>
