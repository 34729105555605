<template>
  <div>
    <!-- Empty state -->
    <iq-empty-state
      icon="send"
      title="Keine Einladungen gefunden"
      v-if="invites.length === 0"
    />

    <!-- Invite item -->
    <div class="item" v-for="invite in invites" :key="invite.id">
      <!-- Avatar -->
      <div>
        <md-avatar class="md-avatar-icon">
          <md-icon class="md-primary">send</md-icon>
        </md-avatar>
      </div>

      <!-- Info -->
      <div>
        <div class="md-title">{{ invite.email }}</div>
        <div class="md-caption">
          {{ new Date(invite.created_at).toLocaleString() }}
        </div>
      </div>

      <!-- Button -->
      <md-menu md-align-trigger v-if="isComponentActive(['admin'])">
        <md-button class="md-icon-button md-primary" md-menu-trigger>
          <md-icon>manage_accounts</md-icon>
          <md-tooltip md-direction="left">Einladung bearbeiten</md-tooltip>
        </md-button>

        <md-menu-content>
          <md-menu-item @click="copyInvite(invite)">
            <md-icon>content_copy</md-icon>
            <span>Link kopieren</span>
          </md-menu-item>

          <md-menu-item @click="resendInvite(invite.id)">
            <md-icon>reply</md-icon>
            <span>Erneut versenden</span>
          </md-menu-item>

          <md-menu-item @click="deleteInvite(invite.id)">
            <md-icon>delete</md-icon>
            <span>Einladung löschen</span>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-duration="6000"
      :md-active.sync="showCopySnackbar"
    >
      Einladung kopiert!
    </md-snackbar>
  </div>
</template>

<script>
import accessControlMixin from "@/mixins/accessControlMixin";

export default {
  name: "companyInvites",
  mixins: [accessControlMixin],
  props: {
    invites: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showCopySnackbar: false
    }
  },
  methods: {
    resendInvite(id) {
      this.$emit("resend", id);
    },
    deleteInvite(id) {
      this.$emit("delete", id);
    },
    copyInvite(invite) {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(invite.invite_url);
      this.showCopySnackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.item {
  margin-top: 1rem;
  padding: $padding;
  border: 1px solid var(--iq-gray-300);
  border-radius: $border-radius;
  background-color: white;
  display: flex;
  align-items: center;

  @include borderHover;

  & > span {
    margin-left: 1rem;
  }

  & > .md-menu {
    margin: 0 0 0 auto;
  }
}
</style>
