import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import "./types";

const COMPANY_URI = Settings.API_URI_COMP;

/** Get company of a user (me) - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<CompanyObject> }
 * @throws { APIError }
 **/
const getCompany = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${COMPANY_URI}/`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/** Update the company of a user (me) - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !string } name Payload
 * @return { Promise<CompanyObject> }
 * @throws { APIError }
 **/
const updateCompany = (name) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${COMPANY_URI}/`,
      data: {
        name: name,
      },
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/** Get all users associated with a company - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<UserObject[]> }
 * @throws { APIError }
 **/
const getCompanyUsers = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${COMPANY_URI}/users`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/** Get all projects associated with a company - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<ProjectObject[]> }
 * @throws { APIError }
 **/
const getCompanyProjects = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${COMPANY_URI}/projects`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/** Get all invites associated with a company - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<InviteObject[]> }
 * @throws { APIError }
 **/
const getCompanyInvites = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${COMPANY_URI}/invites`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export {
  getCompany,
  updateCompany,
  getCompanyUsers,
  getCompanyProjects,
  getCompanyInvites,
};
