<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Neuen Benutzer einladen</md-dialog-title>

    <md-dialog-content>
      <md-field>
        <label>E-Mail-Adresse</label>
        <md-input v-model="email" type="email" />
        <span class="md-helper-text"
          >Bitte geben Sie hier die E-Mail-Adresse des neuen Benutzers
          ein.</span
        >
      </md-field>
      <md-checkbox v-model="send_mail">E-Mail versenden</md-checkbox>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="createInvite"
        >Nutzer einladen</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";

export default {
  name: "companyInviteDialog",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      send_mail: false,
    };
  },
  methods: {
    createInvite() {
      this.$emit("invite-user", { mail: this.email, send_mail: this.send_mail });
      this.reset();
    },
    closeDialog() {
      this.$emit("close");
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-content {
  width: 500px;
}
</style>
