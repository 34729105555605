<template>
  <div>
    <!-- Empty state -->
    <iq-empty-state
      icon="person"
      title="Keine Nutzer gefunden"
      v-if="users.length === 0"
    />

    <!-- User item -->
    <div class="item" v-for="user in users" :key="user.id">
      <!-- Avatar -->
      <div>
        <md-avatar :class="user.status === 'active' ? 'md-avatar-icon' : 'md-avatar-icon-inactive'">
          <md-icon class="md-primary" v-if="user.status === 'active'">person</md-icon>
          <md-icon v-else>pause</md-icon>
        </md-avatar>
      </div>

      <!-- Info -->
      <div>
        <div class="md-title">{{ user.first_name }} {{ user.last_name }}</div>
        <div class="md-body-2">{{ user.type | translateUserType }}</div>
        <div class="md-caption">{{ user.email }}</div>
      </div>

      <!-- Status -->
      <span class="iq-pill iq-pill-gray" v-if="user.status === 'pending'"
        >Ausstehend</span
      >
      <span class="iq-pill iq-pill-green" v-if="user.status === 'active'"
        >Aktiv</span
      >
      <span class="iq-pill iq-pill-red" v-if="user.status === 'inactive'"
        >Inaktiv</span
      >

      <md-button
        class="md-primary md-icon-button"
        v-if="isComponentActive(['admin'])"
        @click="handleEditClick(user)"
      >
        <md-icon>edit</md-icon>
      </md-button>
    </div>

    <md-dialog :md-active="isDialogActive">
      <md-dialog-title>Nutzer anpassen</md-dialog-title>
      <md-dialog-content style="width: 650px">

        <md-field>
          <label>Benutzerrolle auswählen</label>
          <md-select v-model="currentUserRole">
            <md-option value="admin">Administrator</md-option>
            <md-option value="member">Mitarbeiter</md-option>
            <md-option value="ext_member">Externer Mitarbeiter</md-option>
            <md-option value="guest">Gast</md-option>
          </md-select>
          <span class="md-helper-text" v-if="user">Hier können Sie {{ user.first_name }} {{ user.last_name }} eine neue Benutzerrolle zuordnen</span>
        </md-field>

        <md-field style="margin-top: 3rem">
          <label>Status auswählen</label>
          <md-select v-model="currentUserStatus">
            <md-option value="active">Aktiv</md-option>
            <md-option value="inactive">Inaktiv</md-option>
          </md-select>
          <span class="md-helper-text" v-if="user">Hier können Sie {{ user.first_name }} {{ user.last_name }} einen neuen Status zuordnen</span>
        </md-field>

        <div class="md-body-2" style="margin-top: 3rem">
          Inaktive nutzer können sich nicht mehr in die Applikation einloggen.
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-accent" @click="toggleDialog">Schließen</md-button>
        <md-button class="md-primary" @click="handleUpdate"
          >Speichern</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isErrorActive"
      >Ein Fehler ist aufgetreten: {{ errorMessage }}</md-snackbar
    >
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccessActive"
      >Benutzer wurde erfolgreich aktualisiert</md-snackbar
    >
  </div>
</template>

<script>
import accessControlMixin from "@/mixins/accessControlMixin";
import { updateUserOther } from "@/services/api/user";

export default {
  name: "companyUsers",
  mixins: [accessControlMixin],
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDialogActive: false,
      user: null,
      currentUserRole: null,
      currentUserStatus: null,
      errorMessage: null,
      isErrorActive: false,
      isSuccessActive: false,
    };
  },
  methods: {
    toggleDialog() {
      if (this.isDialogActive) this.user = null;
      this.isDialogActive = !this.isDialogActive;
    },
    handleEditClick(user) {
      this.user = user;
      this.currentUserRole = user.type;
      this.currentUserStatus = user.status;
      this.toggleDialog();
    },
    async handleUpdate() {
      if (this.currentUserRole) {
        try {
          let response = await updateUserOther(this.user.id, {
            type: this.currentUserRole,
            status: this.currentUserStatus,
          });
          this.toggleDialog();
          this.toggleSuccess();
          this.$emit("updateSuccess", response);
        } catch (e) {
          console.error(e);
          this.toggleError("API Error");
        }
      } else {
        this.toggleError("Keine Rolle ausgewählt");
      }
    },
    toggleError(reason) {
      this.errorMessage = reason;
      this.isErrorActive = true;
    },
    toggleSuccess() {
      this.isSuccessActive = true;
    },
  },
  filters: {
    translateUserType(val) {
      switch (val) {
        case "admin":
          return "Administrator";
        case "member":
          return "Mitarbeiter";
        case "ext_member":
          return "Externer Mitarbeiter";
        case "guest":
          return "Gast";
        default:
          return (
            val.toString().charAt(0).toUpperCase() + val.toString().slice(1)
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.item {
  margin-top: 1rem;
  padding: 1.5rem 1rem;
  border: 1px solid var(--iq-gray-300);
  border-radius: $border-radius;
  background-color: white;
  display: flex;
  align-items: center;

  @include borderHover;

  & > span {
    margin-left: auto;
  }

  & > .md-button {
  }
}
</style>
