import Interceptor from "@/services/Interceptor";
import Settings from "@/config";

const API_TOKEN_URI = Settings.API_URI_TOKEN;

/**
 * Get the current API token
 **/
const getApiToken = () => {
  return Interceptor({
    method: "GET",
    url: `${API_TOKEN_URI}/`,
  });
};

/**
 * Regenerate API token
 **/
const regenerateApiToken = () => {
  return Interceptor({
    method: "post",
    url: `${API_TOKEN_URI}/`,
  });
};

export { getApiToken, regenerateApiToken };
