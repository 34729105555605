<template>
  <div>
    <!-- Header -->
    <div class="iq-subheader">Allgemeine Informationen</div>

    <!-- List -->
    <md-list>
      <md-list-item>
        <md-icon>business</md-icon>
        <span class="md-list-item-text">Unternehmensname</span>
        <span>{{ company.name }}</span>
      </md-list-item>
      <md-divider class="md-inset" />

      <md-list-item>
        <md-icon>tag</md-icon>
        <span class="md-list-item-text">Unternehmenstyp</span>
        <span>{{ company.type | getCompanyType }}</span>
      </md-list-item>
      <md-divider class="md-inset" />

      <md-list-item>
        <md-icon>calendar_today</md-icon>
        <span class="md-list-item-text">Erstellt</span>
        <span>{{ new Date(company.created_at).toLocaleString() }}</span>
      </md-list-item>
      <md-divider class="md-inset" />

      <md-list-item>
        <md-icon>update</md-icon>
        <span class="md-list-item-text">Aktualisiert</span>
        <span>{{ new Date(company.updated_at).toLocaleString() }}</span>
      </md-list-item>
      <md-divider class="md-inset" />
    </md-list>
  </div>
</template>

<script>
export default {
  name: "companyDetails",
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  filters: {
    getCompanyType(val) {
      if (val === "sponsor") return "Admin";
      else return "Customer";
    },
  },
};
</script>

<style lang="scss" scoped>
.md-list {
  background-color: var(--iq-gray-50);
}
</style>
